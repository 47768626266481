<template>
    <div>
        <v-sheet class="px-6 py-12 ">
            <div class="container">
                <div class="d-flex flex-wrap justify-space-between mb-8">
                    <!-- <v-avatar size="120" class="mr-6">
                        <img
                            class=""
                            src="@/assets/images/avatars/006-woman-1.svg"
                            alt=""
                        />
                    </v-avatar> -->
                    <div class="flex-1">
                        <div class="d-flex align-start justify-space-between">
                            <div class="mb-3">
                                <h5 class="mr-2 mb-0">
                                    {{partner.name}}

                                    <v-tooltip top>
                                        <template
                                            v-slot:activator="{on, attrs}"
                                        >
                                            <v-icon
                                                class="body-1"
                                                color="primary"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                mdi-check-decagram</v-icon
                                            >
                                        </template>
                                        <span>Verified</span>
                                    </v-tooltip>
                                </h5>
                                <p class="text--disabled mb-0">{{partner.contactperson}}</p>
                            </div>
                            <div>
                                <v-chip
                                    class="ma-2"
                                    color="green"
                                    outlined
                                    small
                                >
                                    {{partner.phone}}
                                </v-chip>
                                <v-chip class="" color="blue" outlined small>
                                    {{partner.email}}
                                </v-chip>
                            </div>
                        </div>
                        <div class="d-flex mb-2">
                            <p class="ma-0 mr-8">
                                <span class="font-weight-medium">1,022 </span
                                ><span class="body-2 text--disabled"
                                    >Invoices</span
                                >
                            </p>
                            <p class="ma-0 mr-8">
                                <span class="font-weight-medium">100k </span
                                ><span class="body-2 text--disabled"
                                    >Orders</span
                                >
                            </p>
                            <p class="ma-0 mr-8">
                                <span class="font-weight-medium">1540 </span
                                ><span class="body-2 text--disabled"
                                    >Quotes</span
                                >
                            </p>
                        </div>
                        <p class="text--disabled">
                            {{partner.postalAddress}}
                        </p>
                    </div>
                    <div class="flex-1 text-right">
                        <v-btn fab class="mr-2" small depressed>
                            <v-icon>mdi-email-outline</v-icon>
                        </v-btn>
                        <v-menu offset-y>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn
                                    fab
                                    color=""
                                    small
                                    depressed
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-icon>mdi-eye</v-icon> View As
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-icon
                                            >mdi-comment-text-multiple</v-icon
                                        >
                                        Messages
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-icon>mdi-account-settings</v-icon>
                                        Account Settings
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </div>
            </div>
        </v-sheet>
        <base-card class="transparent">
            <v-tabs v-model="tab" background-color="">
                <v-tab v-for="item in items" :key="item.tab">
                    {{ item.tab }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" class="transparent">
                <v-tab-item class="transparent">
                    <v-row>
                        <v-col cols="12">
                            <base-card class="mb-4">
                                <v-card-title class="body-1"
                                    >Invoices</v-card-title
                                >
                                <v-divider></v-divider>
                                <v-card-text>
                                    
                                </v-card-text>
                            </base-card>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item class="transparent">
                    <base-card class="transparent">
                        <v-card-text>Orders</v-card-text>
                    </base-card>
                </v-tab-item>
            </v-tabs-items>
        </base-card>
    </div>
</template>
<script>
import avatarGroupCard from '@/components/card/AvatarGroupCard'
import { mapGetters } from 'vuex'
export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Profile 1'
    },
    components: {
        avatarGroupCard
    },
    data() {
        return {
            tab: null,
            items: [
                {tab: 'Invoices', content: 'Tab 1 Content'},
                {tab: 'Orders', content: 'Tab 2 Content'}
            ],
            infos: [],
            friends: [],
            images: [],
            country: []
        }
    },
    computed: {
        ...mapGetters(['partner']),
    }
}
</script>
<style lang="scss">
.ul-widget-profile-img {
    position: relative;
}
.ul-widget-profile-img:after {
    // content: "";
    // position: absolute;
    // top: 44px;
    // left: 50%;
    // width: 1px;
    // height: calc(100% - 30px);
    // background: #B3C0CE;
    content: '';
    position: absolute;
    top: 44px;
    left: 50%;
    width: 1px;
    height: calc(100% - 30px);
    background: #b3c0ce;
}
</style>
